import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import * as AcfLayout from '../acf';
import { decodeEntities } from '../utils/htmlParse';

const AcfComponent = (props) => {
  const {
    item,
    location,
    pageContext,
    siteMetadata,
    parent,
    componentName,
    postsOnPage,
    categories,
  } = props;
  const ComponentName = AcfLayout[componentName];
  return (
    <ComponentName
      parent={parent}
      location={location}
      pageContext={pageContext}
      siteMetadata={siteMetadata}
      postsOnPage={postsOnPage}
      categories={categories}
      {...item}
    />
  );
};

const BlogPage = (props) => {
  const {
    data,
    pageContext,
    location,
  } = props;
  const {
    page,
    site,
    postsOnPage,
    categories,
  } = data;
  const {
    title,
    parent,
    yoast,
    acf = {}
  } = page;
  const {
    siteMetadata
  } = site;
  const { title: siteTitle } = site.siteMetadata;
  const standardHeroImage =
    acf.layout.find(block => block.__typename === 'WordPressAcf_StandardHero') ?
    (acf.layout.find(block => block.__typename === 'WordPressAcf_StandardHero').image || null) : null;
  return (
    <Layout location={location}>
      <SEO
        title={yoast.metaTitle ? `${decodeEntities(yoast.metaTitle)}` : `${title} | ${siteTitle}`}
        desc={yoast.metaDescription}
        banner={standardHeroImage && standardHeroImage.source_url}
      />
      {
        (acf && acf.layout) ? acf.layout.map((item, index) => {
          if (!item.__typename) return null;
          const layoutComponentName = item.__typename.replace('WordPressAcf_','');
          return (
            <AcfComponent
              key={item.id || `layoutComponentName-${index}`}
              componentName={layoutComponentName}
              item={item}
              location={location}
              pageContext={pageContext}
              parent={parent}
              siteMetadata={siteMetadata}
              postsOnPage={postsOnPage}
              categories={categories}
            />
          );
        }) :
        null
      }
    </Layout>
  )
}

export default BlogPage;

export const pageQuery = graphql`
  query BlogQuery($limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title
        wordpressUrl
        blogSlug
        categorySlug
        siteUrl
      }
    }
    postsOnPage: allWordpressPost(
      filter: {
        slug: {ne: "gatsby-ignore"}
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    categories: allWordpressCategory(filter:{
        count: {
          gt: 0
        },
        slug: {
          in: [
            "baxters-blog",
            "case-studies",
            "media",
            "news",
            "useful-information-news",
            "videos"
          ]
        }
      }
    ) {
      edges {
        node {
          name
          slug
          count
        }
      }
    }
    page: wordpressPage(slug: { eq: "blog" }) {
      title
      parent: parent_element {
        slug
        title
      }
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
      acf {
        layout: layout_page {
          __typename
          ... on WordPressAcf_BlogFeed {
            id,
          }
          ... on WordPressAcf_StandardHero {
            image {
              id
              source_url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 70) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              alt_text
            }
            hasText
            heading
            text
            hasLink
            link
            linkLabel
          }
          ... on WordPressAcf_SubscribeFormBlock {
            id,
          }
          ... on WordPressAcf_TestimonialsBlock {
            id
            hasLink
            link
            linkLabel
          }
          ... on WordPressAcf_LogosBlock {
            id
            heading
            usingGlobalLogos
            logos {
              logo {
                id
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 70) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
          ... on WordPressAcf_ContactInformationBlock {
            id,
          }
        }
      }
    }
  }
`
